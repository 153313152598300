<template>
  <!-- 栏目，和业务使用同样的样式 -->
  <ul v-if="message.content.length" class="business">
    <li
      v-for="item in message.content"
      :key="item.id"
      class="heightAuto"
      @click="switchPart(item)"
    >
      <img
        :src="item.icon"
        style="display: block; max-width: 100%; height: auto"
      />
    </li>
    <li
      :style="[{ width: '24%' }]"
      style="opacity: 0 !important; height: 0 !important"
    ></li>
    <li
      :style="[{ width: '24%' }]"
      style="opacity: 0 !important; height: 0 !important"
    ></li>
  </ul>
</template>

<script>
import { mapState } from 'vuex'
import { buildData } from '@/components/Bubbles/interface'
import { setNavigation } from '@/api/chat'

export default {
  name: 'BubblePart',
  props: {
    message: Object
  },
  data () {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState(['appConfig'])
  },
  mounted () {
    // this.message.content.push({}, {})
  },
  methods: {
    switchPart (data) {
      // const { platform, orgCode, token } = this.appConfig

      // 是否可以重复选择
      // if (this.selected) {
      //   return false
      // }
      this.selected = data.name
      // 更新当前选择的栏目信息
      this.$store.commit('storeNavigation', data)
      // 把对应的考试列表追加到会话列表
      this.$store.commit('appendBubble', {
        type: 'exam',
        content: data.examList
      })
      // 记录栏目导航
      setNavigation({
        chatId: this.appConfig.chatId,
        platform: this.appConfig.platform,
        orgCode: this.appConfig.orgCode,
        token: this.appConfig.token,
        navigationId: data.id,
        navigationName: data.name
      })
      const it = {
        type: 18,
        content: '咨询其他问题您可以直接在输入框输入'
      }
      this.$store.commit('appendBubble', buildData(it))
      data.level = 1
      this.$store.commit('updateExam', data)
      this.$store.commit('updateExamName', null)
      this.$store.commit('updateExamId', null)
    }
  }
}
</script>
